<template>
   <div class="home">

   </div>
</template>
<script>
export default {
    name: 'Home',
    beforeMount() {
    this.$router.push({ path: '/orders' });
    }
}
</script>